import React, { Component } from 'react';
import Navigation from './components/navigation';
import Header from './components/header';
import About from './components/about';
import Goals from './components/goals';
import Services from './components/services';
import Contact from './components/contact';
import Slider from "react-slideview";

export class App extends Component {
  state = {
    landingPageData: {},
  }

  render() {
    return (
      <div>
        <Navigation />
        <Slider Navigation={true} dots={true} speed={10000} className="your-sliderwrapper-class">
          <div style={{ width: "100%" }}>  <Header /></div>
          <div style={{ width: "100%" }}> <About /> </div>
          <div style={{ width: "100%" }}> <Goals /> </div>
        </Slider>
        <div style={{ width: "100%" }}> <Services /> </div>
        <Contact />
      </div>
    )
  }
}

export default App;
