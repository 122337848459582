import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

export class NavArrows extends Component {

  render() {
    const { t } = this.props;
    return (
      <div style={{ width: 200, borderRadius: 50, alignSelf: 'center', backgroundColor: this.props.backgroundColor, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <div style={{ display: "inline-block" }}>
            <a
              href={this.props.previous}
              className="btn btn-custom btn-lg page-scroll"
            >
              <i className={"fa fa-arrow-left"}></i>
            </a>
          </div>
          <div style={{ display: "inline-block" }}>
            <a
              href={this.props.next}
              className="btn btn-custom btn-lg page-scroll"
            >
              <i className={"fa fa-arrow-right"}></i>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(NavArrows);
